#Div-Holder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10rem !important;
}
.sidebar1 {
    display: flex;
    flex-direction: column;
    border-left: 0;
    font-family: 'Nunito';
    padding: 2rem;
    border-radius: 1rem;
    color: black;
    height: 120%;
    width: 50%;
    background-color: white;
    border: 1px solid #F6C506;
    box-shadow: 0px -1px 13px 0px rgba(111, 111, 111, 0.352);
    -webkit-box-shadow: 0px -1px 13px 0px rgba(111, 111, 111, 0.266);
    ;
    -moz-box-shadow: 0px -1px 13px 0px rgba(111, 111, 111, 0.401);
    ;


}

.sidebar2 {
    border-right: 0;
    width: 100%;
    height: 120%;
    border-radius: 1rem;
    color: black;
    background-color: white;

}

.sidebar2 img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
}

.sidebar1-Title {
    text-align: center;
    font-family: 'Nunito';
    margin-top: 2rem;

}

.sidebar1-Title2 {
    text-align: center;
    font-family: 'Nunito';
    margin-bottom: 3rem;
}

.sidebar1-Title3 {
    font-size: 0.8rem;
    margin-top: 1rem;
    text-align: center;
    font-family: 'Nunito';
}
.form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}
.form-inner
{
    display: flex;
}

.sidebar1-text {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 2rem;
    background-color:white;
    color: black;
    border: 1px solid black;
}
.sidebar1-text1 {
    width: 49%;
    padding: 0.5rem;
    margin-bottom: 2rem;
    background-color:white;
    color: black;
    border: 1px solid black;
    margin-right: 2%;
}
.sidebar1-text2 {
    width: 49%;
    padding: 0.5rem;
    margin-bottom: 2rem;
    background-color:white;
    color: black;
    border: 1px solid black;
}
.sidebar1-text1C
{
    width: 49%;
    padding: 0.5rem;
    margin-bottom: 2rem;
    background-color:white;
    color: black;
    border: 1px solid black;
}
.sidebar1-text2C
{
    width: 49%;
    padding: 0.5rem;
    margin-bottom: 2rem;
    background-color:white;
    color: black;
    border: 1px solid black;
    margin-left: 1rem !important;
}
.form-inner-2
{
    display: flex;
    justify-content: center;
    width: 100%;
}

.login-2 {
    display: flex;
    justify-content: right;
    padding-right: 0;
    width: 100%;
}
.sidebar1-text3 {
    width: 51%;
    padding: 0.5rem;
    background-color:white;
    color: black;
    border: 1px solid black;
    margin-right: 1%;
    margin-left: 1rem !important;
}

.login-button {
    background-color: #F6C506;
    border: 1px solid white;
    font-family: 'Nunito';
    color: black;
    width: 40%;
    padding: 0.5rem;
    border-radius: 8px;
    transition-duration: 1s;
}

.login-button:hover {
    background-color: transparent;
    border: 1px solid #F6C506;
    font-family: 'Nunito';
    color: black;
    padding: 0.5rem;
    border-radius: 3px;
}
@media (max-width: 760px) {
    #Div-Holder {
        grid-template-columns: 100%;
    }    

    .sidebar2 img {
        border-radius: 1rem;
    }
    .sidebar1 {
        width: 100%;
        border-radius: 1rem;
    }    
    


}
