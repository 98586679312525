#Contact-Body
{
    background-color: white;
    color: black;
    font-family: 'Nunito';
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 2rem;
    border: 2px solid #F6C506;
    border-radius: 0.8rem;
}
.Map
{
    width: 100%;
    height: 500px;
}

@media (max-width: 760px) {
    #Contact-Body
    {
        grid-template-columns: 100%;
    }
}
.Contact
{
    text-align: center;
}
.Contact1
{
    text-align: center;
    color: #F6C506;
}
.Contact-Holder
{
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
}
.Contact-Holder2
{
    padding: 2rem;
}