#Footer_MainA {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: black;
    background-color: whitesmoke;
}

.admin-login {
    margin-left: 25%;
    width: 45%;
    padding: 3rem;
    border-radius: 10px;
    background-color: white;
    color: black;
    box-shadow: 2px -2px 11px 0px rgba(102, 102, 102, 0.75);
    -webkit-box-shadow: 2px -2px 11px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px -2px 11px 0px rgba(0, 0, 0, 0.75);
}
.sidebar1A-text {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 2rem;
    background-color:white;
    color: black;
    border: 1px solid black;
    margin-right: 2%;
    border-radius: 0.2rem;
}

.Admin-Login-Page {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    background-color: white;
}

@media (max-width: 760px) {
    .admin-login {
        width: 100%;
        margin-left: 0%;
    }    
    #Footer_MainA
    {
        grid-template-columns: 100%;
    }
}
