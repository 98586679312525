.Services-Head {
    color: black;
    text-align: center;
    margin-top: 1.5rem;
    font-family: 'Nunito';
    font-size: 1.5rem;
}

.Services-Container {
    display: grid; 
    grid-template-columns: 50% 50%;
}

.S-Container-1 {
    display: flex;
    justify-content: center;
}

.S-Container-2 {
    padding: 1rem;
}

.S-Container-2 h5 {
    color: black;
    font-family: 'Nunito';
}

.S-Container-2 h5 span {
    color: #F6C506
}

.S-Container-2 p {
    color: black;
    font-family: 'Nunito';
    font-size: 0.9rem;
    text-align: justify;
}

.S-Container-2 ul {
    color: black;
    font-family: 'Nunito';
    font-size: 0.9rem;
    text-align: justify;

}

.S-Container-1 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.Touch {
    display: flex;
    justify-content: center;
    background-color: #F6C506;
    color: black;
    font-family: 'Nunito';
    border: 1px solid #F6C506;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    transition-duration: 1s;
}

.Touch:hover {
    background-color: transparent;
    color: black;
    border: 1px solid #F6C506;
}

.Service-Divider {
    width: 200%;
    color: #F6C506;
    border: 1px solid #F6C506;
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-50%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(50%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.fadeInLeft {
    animation-name: fadeInLeft;
}

.fadeInRight {
    animation-name: fadeInRight;
}

.Van-Lock {
    padding-left: 2rem;
    color: black;
    font-family: 'Nunito';
}

.Van-Lock1 {
    padding: 2rem;
    padding-top: 0rem;
    color: black;
    font-family: 'Nunito';
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
}

.VanLock-Inner {
    text-align: center;
    padding: 2rem;
    padding-top: 0rem;
    color: black;
    font-family: 'Nunito';
}

.VanLock-Inner img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.VanLock-Inner h6 {
    margin-top: -1rem;
    padding: 0.5rem;
    border: 1px solid #F6C506;
    border-top: 0rem;
}

.RequestForm {
    display: grid;
    grid-template-columns: 50% 50%;
}

.FCont1 {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: space-between;
    color: black;
    padding: 1rem;
    text-align: center;
    font-family: 'Nunito';
    border: 2px solid #F6C506;
    margin: 1.5rem;
}

.FCont2 {
    color: black;
    padding: 1rem;
}

.Areas-Div {
    margin-top: 1rem;
}

.Areas-Div h6 {
    margin-top: 1rem;
}

.FCont1 h4 span {
    color: #F6C506;
}

.Request-Input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #F6C506;
    margin-top: 0.5rem;
    background-color: transparent;
    color: black;
    font-family: 'Nunito';
}

.Request-Input::placeholder {
    color: black;
    font-family: 'Nunito';
}

#Dropdown-Request {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #F6C506;
    margin-top: 0.5rem;
    background-color: white;
    color: black;
    font-family: 'Nunito';
}

#Request-area {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #F6C506;
    margin-top: 0.5rem;
    background-color: transparent;
    color: black;
    font-family: 'Nunito';
}

#Request-area::placeholder {
    color: black;
    font-family: 'Nunito';
}

.Request-Button {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #F6C506;
    margin-top: 0.5rem;
    background-color: #F6C506;
    color: black;
    font-family: 'Nunito';
    transition-duration: 1s;
}

.Request-Button:hover {
    background-color: transparent;
    color: black;
    border: 1px solid #F6C506;
}


@media (max-width: 768px) {
    .Services-Container {
        grid-template-columns: 100%;
    }

    .Van-Lock1 {
        grid-template-columns: 100%;
    }
    .RequestForm {
        grid-template-columns: 100%;
    }
}
.fix h4
{
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-family: 'Nunito';
    color: black;
    font-weight: 600;
}
.FixingProduct
{
    display: grid;
    grid-template-columns: 25% 25% 25% 25% ;
    grid-gap: 1rem;
    margin-top: 1rem;
}

.FixingProduct__card
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 1rem;
    border: 1px solid #F6C506;
    border-radius: 0.5rem;
    transition-duration: 1s;
}

.ProductImage-Container
{
    width: 100%;
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ProductImage-Container img
{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 
    1px solid gainsboro
    ;
}
.FixingProduct__info
{
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
}
.ProductHeight{
    height: 13rem;
}
.product-btn
{
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #F6C506;
    margin-top: 0.5rem;
    background-color: #F6C506;
    color: black;
    font-family: 'Nunito';
    transition-duration: 1s;
}
.product-btn:hover{
    background-color: transparent;
    color: black;
    border: 1px solid #F6C506;
}

@media (max-width:768px) {
    .FixingProduct
    {
        grid-template-columns: 50% 50%;
    } 
}

@media (max-width: 576px) {
    .FixingProduct
    {
        grid-template-columns: 100%;
    } 
}