#Footer_Main {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: black;
    background-color: whitesmoke;
    margin-top: 1rem;
}

#Footer-Component-1 {
    padding: 1rem;
}

#Footer-Component-3 {
    padding: 1rem;
    display: flex;
    justify-content: center;
}

.Divider-Text6 {
    margin-top: 2.5rem;
    color: black;
    font-size: 1rem;
    font-weight: 600;
    bottom: 0;
}

#Footer-Component-1 h3 span {
    color: #F6C506;
}

.FooterContainer {
    background-color: #F6C506;
    color: black;
    font-family: 'Nunito';
    padding: 15px;
    flex-shrink: 0;
    margin-top: auto;
}

.Content {
    text-align: center;
}