.Order-Details
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-size: 1.3rem;
    font-family: 'Nunito';
    margin-top: 1.5rem;
}
#ViewOrders
{
    display: grid;
    grid-template-columns: 50% 50%;
    border: 1px solid #F6C506;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 8px;
}
.Order-HisA
{
    color: black;
}
.Order-Details-HolderA
{
    padding: 2rem;
    font-family: 'Nunito';
}
.Account-Info
{
    border-radius: 0.5rem;
    padding: 1rem;
    width: 100%;
    font-family: 'Nunito';
    margin-top: 0rem;
    margin-left: 0.5rem;
}
#Plate-Box
{
    text-align: center;
    border: 2px solid #F6C506;
    background-color:#F6C506;
    color: black;
    width: 100%;
    font-size: 1rem;
    font-family: 'Nunito';
    font-weight: bold;
}
#Plate-Box1
{
    background-color:#F6C506;
    display: flex;
    border-bottom: 1px solid white;
    padding: 0.5rem;
    justify-content:space-between;
    color: black;
    width: 100%;
    font-size: 1rem;
    font-family: 'Nunito';
}
#Plate-Box2
{
    display: flex;
    border-bottom: 1px solid #F6C506;
    padding: 0.5rem;
    justify-content:space-between;
    color: black;
    width: 100%;
    font-size: 1rem;
    font-family: 'Nunito';
}
.PlatesAdmin
{
    margin-top: 2rem;
}
.back-btn
{
    margin-top: 10%;
    width: 80%;
    font-size: 1rem;
    font-family: 'Nunito';
    background-color: #F6C506;
    color: black;
    border: 1px solid #F6C506;
    padding: 0.5rem;
    border-radius: 0.3rem;
    transition-duration: 1s;
    margin-bottom: 1rem;
}
.back-btn:hover
{
    background-color: transparent;
    color: black;
    border: 1px solid #F6C506;
    padding: 0.5rem;
    border-radius: 0.3rem;
}
@media (max-width: 760px) {
    #ViewOrders
    {
        grid-template-columns: 100%;
    }
    .PlatesAdmin
    {
        margin-top:0%;
    }
.back-btn
{
    margin-top: -5rem;
    width: 100%;
    font-size: 1rem;
    font-family: 'Nunito';
    background-color: #F6C506;
    color: white;
    border: 1px solid black;
    padding: 0.5rem;
    border-radius: 0.3rem;
    transition-duration: 1s;
}
}

.CartContainer
{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}
.Acc-Header
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-family: 'Nunito';
}
.Acc2
{
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
    font-family: 'Nunito';
}
.Accessories
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}
.CartProduct
{
    width: 120%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid gray;
}
.CartProduct h6
{
    font-family: 'Nunito';
    font-size: 1rem;
    color: black;
    font-weight: 800;
}
.CartProduct img{
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
}

@media (max-width:480px) {
    .CartProduct
    {
        width: 100%;
    }
    
    .back-btn{
        width: 80%;
        margin-top: 4rem;
    }
}