body {
    background-color: white;
}

#Navbar {
    width: 100%;
    display: grid;
    grid-template-columns: 45% 55%;
    justify-content: space-around;
    align-items: center;
    color: black;
    padding: 1.5rem;
    background-color: white;
    border-bottom: 1px solid #F6C506;
    font-weight: 800;
}

#NewNavbar {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    color: white;
    padding: 1.5rem;
    background-color: black;
    border-bottom: 1px solid #F6C506;
}

#Component-1 {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 5rem;
}

#Component-1 h3 span {
    color: #F6C506;
}

.Component-Red {
    color: #F6C506;
}

#Component-2 {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 1rem;
    gap: 1rem;
}

#Component-2 h6 {
    font-family: 'Nunito';
    font-weight: 300;
    margin-left: 1rem !important;
}

#Component-2 h3 {
    margin-top: -1rem;
}

#Cover-Holder {
    position: relative;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    margin-bottom: 1rem;
}

#Cover-Holder img {
    display: block;
    width: 100%;
    object-fit: cover;
}



@media (max-width: 768px) {

    #Navbar {
        grid-template-columns: 100%;
    }

    #Component-1 {
        display: flex;
        justify-content: center;
        margin-left: 0%;
        padding: 0;
    }

    #Component-2 {
        display: flex;
        margin-right: 0%;
        justify-content: center;
        margin-bottom: 1rem;
    }

    #Component-2 h6 {
        font-size: 0.9rem;
        margin-right: 1rem;
        font-family: 'Nunito';
        font-weight: 300;
    }

}

@media (max-width: 480px) {
    #Navbar {
        grid-template-columns: 100%;
    }

    #Component-1 {
        display: flex;
        justify-content: center;
        margin-left: 0%;

    }

    #Component-2 {
        display: flex;
        margin-right: 0%;
        justify-content: center;
        margin-bottom: 1rem;
    }

    #Component-2 h6 {
        font-size: 0.9rem;
        margin-right: 1rem !important;
        font-family: 'Nunito';
        font-weight: 300;
    }

}

.drawer {
    background-color: aquamarine;
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}
.Drawer-Top{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F6C506 !important;
}
.Drawer-Inner{
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 6rem;
}
.Drawer-Inner div{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: black;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    font-family: 'Nunito';
    font-weight: 300;
}
.Drawer-Inner div:hover{
    color: #F6C506;
    transition: 0.5s;
}

.close-icon {
    font-size: 1.3rem;
    margin-top: -0.5rem;
    cursor: pointer;
    cursor: pointer;
    color: black !important;
}

.Request {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -0.5rem;
    color: black;
    border: 1px solid #F6C506;
    background-color: #F6C506;
    padding: 0.5rem;
    font-family: 'Nunito';
    font-size: 0.9rem;
}

.Request:hover {
    background-color: transparent;
    color: black;
    border: 1px solid #F6C506;
    transition: 0.5s;
}

#NavbarPT {
    width: 100%;
    display: grid;
    grid-template-columns: 70% 30%;
    align-items: center;
    color: black;
    border-bottom: 1px solid #F6C506;
    padding: 1.5rem;

}

#NavbarPT1 {
    width: 100%;
    align-items: center;
    color: black;
    margin-left: 2rem;
}

#NavbarPT2 {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    color: white;
    background-color: black;
}

@media (max-width: 768px) {
    #NavbarPT {
        grid-template-columns: 100%;
    }

    #NavbarPT1 {
        display: flex;
        justify-content: center;
        margin-left: 0%;
    }
}

.mobile-menu {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -2rem;
    padding-top: 1.5rem;
    margin-bottom: -1rem;
}

.mobile-menu img {
    width: 55%;
    height: 50%;
    object-fit: cover;
    margin-right: 1rem;
    margin-top: -4rem;
    margin-bottom: -1.5rem;
}

.mobile-dropdown {
    background-color: white;
}

.menu-icon {
    font-size: 2rem;
    margin-top: -2rem;
    cursor: pointer;
    cursor: pointer;
    color: black;
}

.RequestB {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
    color: black;
    border: 1px solid #F6C506;
    background-color: #F6C506;
    padding: 0.5rem;
    font-family: 'Nunito';
    font-size: 0.9rem;
}

#Component-1 img {
    width: 30%;
    height: 30%;
    object-fit: cover;
    margin-right: 1rem;
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
}

@media (max-width: 768px) {
    #Component-1 img {
        width: 50%;
        height: 50%;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

}
.CartIcon{
    font-size: 1.5rem;
    margin-top: -1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    color: black !important;
}
.CartMobile
{
    margin-top: 1rem;
   font-size: 1.2rem;
}
.CartIconMobile
{
    font-size: 2.2rem;
    margin-top: -3rem !important;
    margin-bottom: 3rem;
    margin-left: 2rem;
    cursor: pointer;
    color: black !important;
}

@media (max-width: 368px) {
    .CartIconMobile
    {
        margin-left: 0.5rem;
    }
}
 
@media (max-width: 1200px) {
    .Request{
        font-size: 0.8rem;
    }
}